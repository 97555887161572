var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass:
        "flex w-full justify-between py-3 px-2 \n        hover:text-black rounded \n        focus:outline-none",
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c(
        "p",
        { staticClass: "flex space-x-3 items-center text-base mb-2" },
        [
          _c("consultation-type-badge", { attrs: { type: _vm.type } }),
          _vm._t("dateOfInteraction"),
          _vm._t("title"),
        ],
        2
      ),
      _c("fv-icon", { attrs: { icon: "chevron-down" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }