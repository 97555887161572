<template>
  <div>
    <div
      v-if="loadedImages.length"
      class="w-full my-4"
    >
      <div
        id="images"
        class="flex flex-wrap gap-y-2 gap-x-2"
      >
        <div
          v-for="(image,index) in loadedImages"
          :key="index"
          class="w-1/4 relative border-2 shadow cursor-pointer appointment-image"
          :class="{'border-primary' : selectedImages.includes(image.fileName)}"
          @click="gallery ? showImage(index) : handleSelectedImage(image)"
        >
          <img
            class="object-cover"
            :src="image.src || image"
          >
          <div v-if="!gallery">
            <fv-icon
              v-if="selectedImages.includes(image.fileName)"
              icon="check-mark-alt"
              class="absolute text-primary"
              style="left: .4rem; bottom: .4rem;"
            />
            <fv-icon
              v-else
              icon="add"
              class="absolute text-white"
              style="left: .3rem; bottom: .3rem;"
            />
          </div>
        </div>
      </div>
    </div>

    <base-modal
      :show="showImageModal"
      @close="showImageModal = false, selectedImage = 0"
    >
      <div class="relative">
        <img :src="loadedImages[selectedIndex].src">
        <div
          v-show="loadedImages.length > 1"
          class="flex justify-between absolute w-full px-4"
          style="top: 47%;"
        >
          <font-awesome-icon
            :class="{'invisible' : selectedIndex === 0}"
            icon="angle-left"
            size="3x"
            class="hover:opacity-75 cursor-pointer text-white"
            @click="selectedIndex = selectedIndex - 1"
          />
          <font-awesome-icon
            :class="{'invisible' : selectedIndex === loadedImages.length-1}"
            icon="angle-right"
            size="3x"
            class="hover:opacity-75 cursor-pointer text-white"
            @click="selectedIndex = selectedIndex + 1"
          />
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api';
import BrokenImage from '@/assets/img/broken-image.svg';
import SpinnerSVG from '@/assets/img/spinner.svg';
import { AppointmentApi } from '@/api';

export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
    appointment: {
      type: Object,
      required: true,
    },
    gallery: {
      type: Boolean,
      default: true,
    },
    initialImages: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
  },

  setup(props) {
    const { images, appointment } = props;

    const result = ref(images.map(() => SpinnerSVG));

    onMounted(() => {
      images.forEach(async (f, index) => {
        const fileName = f.image_name;
        try {
          const image = await AppointmentApi.getImage({
            userId: appointment.customer_id,
            fileName,
          });
          const payload = { index, image, fileName, src: image, loaded: true };
          result.value.splice(index, 1, payload);
        } catch (error) {
          const brokenPlaceholder = {
            index,
            src: BrokenImage,
            image: BrokenImage,
            fileName: 'broken image',
            loaded: false,
          };
          result.value.splice(index, 1, brokenPlaceholder);
        }
      });
    });

    return { loadedImages: result };
  },

  data() {
    return {
      selectedIndex: 0,
      showImageModal: false,
      selectedImages: this.$props.initialImages,
    };
  },
  methods: {
    showImage(index) {
      this.selectedIndex = index;
      this.showImageModal = true;
    },
    handleSelectedImage(image) {
      if (!image.fileName || !image.loaded) return;
      const { fileName } = image;

      if (!this.selectedImages.includes(fileName))
        this.selectedImages.push(fileName);
      else {
        const index = this.selectedImages.indexOf(fileName);

        if (index > -1) {
          this.selectedImages.splice(index, 1);
        }
      }
      this.$emit('selected-images', this.selectedImages);
    },
  },
};
</script>
