<template>
  <div class="flex flex-col mb-6 space-y-4">
    <div
      v-for="referral in referralList"
      :key="referral.id"   
      class="flex flex-col w-full border py-3 px-3 space-y-4 rounded shadow-sm bg-white"
    >
      <div class="flex flex-col space-y-4">
        <div
          v-if="referral.clinic"
          class="space-y-4"
        >
          <div class="font-bold flex justify-between items-center">
            <p class=" font-display tracking-wide text-lg">
              {{ referral.clinic.name }}
            </p>
            <p class="text-xs font-normal">
              {{ referral.created_at }}
            </p>
          </div>
          <div>
            <p>
              <span class="font-semibold">Email: </span> 
              <span v-if="referral.email">{{ referral.email }}</span>
              <span v-else> 
                {{ referral.clinic.referral_email ? referral.clinic.referral_email : referral.clinic.email }} 
              </span>
            </p>
            <p class="">
              <span class="font-semibold">Tel:</span> {{ referral.clinic.phone }}
            </p> 
            <p>
              <span class="font-semibold">Address:</span> 
              {{ referral.clinic.adress }} {{ referral.clinic.postcode }} {{ referral.clinic.city }}
            </p>
          </div>
        </div>
        <div v-if="!referral.clinic && referral.email">
          <div class="font-bold flex justify-between">
            <p>
              {{ referral.email }}
            </p>
            <p class="text-xs font-normal">
              {{ referral.created_at }}
            </p>
          </div>
        </div>
      </div>

      <div
        v-if="referral.reason"
      >
        <p class="font-bold mb-2">
          Reason for clinic visit / clinical diagnosis:
        </p>
        <p>
          {{ referral.reason }}
        </p>
      </div>

      <div
        v-if="referral.referral_contact_option || referral.contact"
      >
        <p class="font-bold mb-2">
          Further contact:
        </p>
        <p>
          {{ referral.referral_contact_option ? referral.referral_contact_option.name : referral.contact }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    referrals: {
      type: Array,
      required: true,
    },
  },
  computed: {
    referralList() {
      const list = this.referrals;
      const sortedList = list.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });

      return sortedList;
    },
  },
};
</script>
