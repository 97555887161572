<template>
  <div>
    <title-row>
      Customer
    </title-row>
    <display-row
      :display-value="appointment.user ? appointment.user.display_name : ''"
      label-text="Name:"
    />
    <display-row
      :display-value="appointment.user.mobile_phone_formatted"
      :has-copy-button="true"
      label-text="Phone number:"
    />

    <display-row
      :display-value="appointment.user.email"
      label-text="Email:"
    />

    <display-row
      v-if="appointment.user.us_state && appointment.user.us_state.state"
      :display-value="appointment.user.us_state.state"
      label-text="State:"
    />

    <display-row
      v-if="userIsInSweden"
      :display-value="appointment.user.ssn"
      :has-copy-button="true"
      label-text="SSN:"
    />

    <animal-insurance-display-row :animal="animal" />
  </div>
</template>



<script>
import { mapState } from 'vuex';
import AnimalInsuranceDisplayRow from './booking_info_rows/AnimalInsuranceDisplayRow.vue';

export default {
  components: { AnimalInsuranceDisplayRow },
  props: {
    appointment: {
      type: Object,
      required: true,
    },
    animal: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['platform']),
    userIsInSweden() {
      return this.platform === 'sv';
    },
  },
};
</script>

<style scoped lang="postcss">
a {
  @apply underline;
}
</style>
