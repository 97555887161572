<template>
  <div class="space-y-6">
    <div
      v-if="!appointment.id && $route.params.id"
      class="mb-3 flex items-center space-x-3 rounded border border-red-200 bg-red-100 p-4 text-red-900"
    >
      <span><font-awesome-icon icon="exclamation-circle" /></span>
      <span
        >No appointment found with id
        <span class="font-semibold">{{ $route.params.id }}</span></span
      >
    </div>
    <base-button
      v-if="!appointment.id && $route.params.id"
      class="mt-1"
      size="sm"
      color="light"
      @click="$router.go(-1)"
    >
      Go back to previous page
    </base-button>

    <span
      v-if="caseType || bookingMetaData"
      class="inline-block space-y-1 rounded border border-beigeLight bg-beigeLighter p-1 px-2"
    >
      <div v-if="caseType" class="flex items-center space-x-3">
        <img :src="caseType.icon_url" alt="" class="h-7 w-7" />
        <p>{{ caseType.name }}</p>
      </div>
      <p v-if="bookingMetaData">
        <span class="font-bold">Internal triage by:</span>
        {{ bookingMetaData.user.display_name }}
      </p>
    </span>

    <div v-if="images.length">
      <title-row> Images </title-row>

      <image-gallery :appointment="appointmentModel" :images="images" />
    </div>

    <div
      v-if="appointment.description && appointment.description.trim() !== ''"
    >
      <title-row> Provided Information </title-row>
      <display-row>
        {{ appointment.description }}
      </display-row>
    </div>

    <div
      v-if="animal && animal.id"
      class="relative float-right inline-block cursor-pointer"
    >
      <button data-testid="edit-animal-details" @click="toggleEditMode">
        <fv-icon v-if="!isInEditMode && isEditingEnabled" icon="edit-alt" />
      </button>
    </div>

    <ValidationObserver v-slot="{ handleSubmit }" tag="form">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <animal-section
          v-if="animal && animal.id"
          :key="animalSectionKey"
          ref="animalSection"
          :class="isLoading ? 'opacity-50' : ''"
          :animal="animalModel"
          :appointment="appointmentModel"
          :is-in-edit-mode="isInEditMode"
        />

        <div v-if="isInEditMode" class="flex w-full justify-end space-x-2 py-4">
          <base-button
            type="button"
            color="cancel"
            :disabled="isLoading"
            @click.prevent.native="toggleEditMode"
          >
            Cancel
          </base-button>

          <base-button
            type="submit"
            color="primary"
            :loading="isLoading"
            data-testid="save-button"
          >
            Save
          </base-button>
        </div>
        <form />
      </form>
    </ValidationObserver>

    <customer-section
      v-if="appointment && appointment.id"
      :animal="animalModel"
      :appointment="appointmentModel"
    />

    <booking-section
      v-if="appointment && appointment.id && appointment.booking"
      :appointment="appointmentModel"
    />
  </div>
</template>

<script>
import deepClone from 'lodash/cloneDeep';
import { mapActions, mapMutations, mapState } from 'vuex';
import isFinite from 'lodash/isFinite';
import ImageGallery from '../images/ImageGallery.vue';
import AnimalSection from './BookingInformationAnimalSection.vue';
import CustomerSection from './BookingInformationCustomerSection.vue';
import BookingSection from './BookingInformationBookingSection.vue';

export default {
  components: {
    ImageGallery,
    AnimalSection,
    CustomerSection,
    BookingSection,
  },
  props: {
    appointment: {
      type: Object,
      required: true,
    },
    animal: {
      type: Object,
      required: true,
    },
    images: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    isEditingEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    bookingMetaData: {
      type: Object || null,
      default: null,
    },
  },

  data() {
    return {
      isValid: false,
      isInEditMode: false,
      isLoading: false,
      animalModelCache: undefined,
      appointmentModelCache: undefined,

      /* Updating these keys forces vue to re-render the component. They are needed because
		 vue will not detect a value has changed if it is an object or array that has some
		 internal property or index updated. */
      animalSectionKey: 0,
      /* ------------------------------------ */
    };
  },

  computed: {
    ...mapState('appointment', ['caseType']),
    /**
		If we are in edit mode, we return the cached model,
		if we're in "normal mode", then we return the "real" model. The reason for this is so
		that changes will not bubble down to the model automatically upon updating fields.
		Only when the "save" button is pressed will we actually update the state.
		@Returns {Object} - either the real model or a cached version if we are in edit mode.
	*/
    appointmentModel() {
      return this.isInEditMode ? this.appointmentModelCache : this.appointment;
    },

    animalModel() {
      return this.isInEditMode ? this.animalModelCache : this.animal;
    },
  },

  methods: {
    ...mapActions('appointment', ['updateAppointment']),
    ...mapActions('animal', ['updateAnimal']),
    ...mapMutations('appointment', ['setWeightData']),

    async onSubmit() {
      // ensure pure function & copy cached values before the are reset
      const appointmentClone = deepClone(this.appointmentModelCache);
      const animalClone = deepClone(this.animalModelCache);
      const enteredWeight = appointmentClone.weight
        ? Number.parseFloat(appointmentClone.weight.replace(',', '.'))
        : null;

      const weightIsEntered = isFinite(enteredWeight) && enteredWeight > 0;
      const weightUnitIsEntered =
        appointmentClone.weight_unit &&
        !Number.isNaN(Number(appointmentClone.weight_unit.id));

      const stateWeightData = {
        weight_unit: appointmentClone.weight_unit,
      };

      const animalAPIPayload = {
        id: animalClone.id,
        payload: animalClone,
      };

      this.isLoading = true;

      const appointmentAPIPayload = {};
      const apiUpdates = [this.updateAnimal(animalAPIPayload)];

      // Only send a weight property if it is entered (don't send anything otherwise)
      if (weightIsEntered) {
        appointmentAPIPayload.weight = enteredWeight;
        stateWeightData.weight = String(enteredWeight);
      }

      // Only send a weight_unit_id property if it is entered (don't send anything otherwise)
      if (weightUnitIsEntered) {
        appointmentAPIPayload.weight_unit = appointmentClone.weight_unit.id;
        apiUpdates.push(this.updateAppointment(appointmentAPIPayload));
      }

      try {
        await Promise.all(apiUpdates);
        this.setWeightData(stateWeightData);

        this.$notify({
          group: 'primary',
          text: 'Animal information updated',
        });

        this.isLoading = false;
        this.toggleEditMode();
      } catch (error) {
        this.$notify({
          group: 'error',
          title: 'Something went wrong',
          text: error.message,
        });
        this.isLoading = false;
      }
    },

    toggleEditMode() {
      this.appointmentModelCache = deepClone(this.appointment);
      this.animalModelCache = deepClone(this.animal);
      this.animalSectionKey += 1;
      this.isInEditMode = !this.isInEditMode;
    },
  },
};
</script>

<style scoped lang="postcss">
a {
  @apply underline;
}
</style>
