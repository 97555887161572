<template>
  <div class="flex flex-col space-y-6">
    <div class="flex flex-col divide-y">
      <button
        v-if="appointment.journal && appointment.journal.status"
        class="text-left py-2 bg-beigeLighter px-2 hover:bg-gray-100 focus:outline-none focus:text-black"
        @click="toggleJournalSummary"
      >
        <div class="flex justify-between items-center">
          <span>Journal</span> <fv-icon icon="chevron-down" />
        </div>
      </button>
      <div
        v-if="showJournalSummary && appointment.journal"
        class="px-2 py-4"
      >
        <journal
          :display-post-comment-section="false"
          :journal="appointment.journal"
          :user-name="appointment.booking && appointment.booking.veterinary ? 
            appointment.booking.veterinary.display_name : 'No vet assigned yet'"
          :product-recommendations="productRecommendations"
        />
      </div>


      <button
        class="text-left py-2 bg-beigeLighter px-2 hover:bg-gray-100 focus:outline-none focus:text-black"
        @click="toggleInformationSummary"
      >
        <div class="flex justify-between items-center">
          <span>Booking information</span> <fv-icon icon="chevron-down" />
        </div>
      </button>
      <div
        v-show="showInformationSummary"
        class="px-2 py-4"
      >
        <booking-information
          :appointment="appointment"
          :animal="animal"
          :images="appointment.images"
        />
      </div>


      <button
        v-if="appointment.referrals.length"
        class="text-left py-2 bg-beigeLighter px-2 hover:bg-gray-100 focus:outline-none focus:text-black"
        :disabled="!appointment.referrals.length"
        :class="{'cursor-not-allowed': !appointment.referrals.length}"
        @click="toggleReferralsSummary"
      >
        <div class="flex justify-between items-center">
          <span :class="{'line-through' : !appointment.referrals.length }">
            Referrals
          </span>
          <fv-icon icon="chevron-down" />
        </div>
      </button>

      <div
        v-show="showReferralsSummary"
        class="px-2 py-4"
      >
        <referral-summary :referrals="appointment.referrals" />
      </div>

      <button
        v-if="appointment.uploads.length"
        class="text-left py-2 bg-beigeLighter px-2 hover:bg-gray-100 focus:outline-none focus:text-black"
        :disabled="!appointment.uploads.length"
        :class="{'cursor-not-allowed': !appointment.uploads.length}"
        @click="toggleFileUploadsSummary"
      >
        <div class="flex justify-between items-center">
          <span :class="{'line-through' : !appointment.uploads.length }">
            Uploaded files
          </span>
          <fv-icon icon="chevron-down" />
        </div>
      </button>
      <div
        v-show="showFileUploadsSummary"
        class="px-2 py-4"
      >
        <appointment-upload
          :appointment-id="appointment.id"
          :uploads="appointment.uploads"
          :extensions="['.pdf', '.doc', '.docx', '.rtf', '.jpg', '.png', '.jpeg']"
          :show-upload-button="false"
          :show-delete-button="false"
          :display-title="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AppointmentUpload from '@/components/AppointmentUpload.vue';
import Journal from '@/components/journal/Journal.vue';
import { mapGetters, mapState } from 'vuex';
import ReferralSummary from '@/components/appointments/ReferralSummary';
import BookingInformation from '@/components/booking/BookingInformation';
import { EcomApi } from '@/api';

export default {
  components: {
    BookingInformation,
    Journal,
    AppointmentUpload,
    ReferralSummary,
  },
  props: {
    appointment: {
      type: Object,
      required: true,
    },
    animal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showInformationSummary: false,
      showJournalSummary: false,
      showReferralsSummary: false,
      showFileUploadsSummary: false,
      productRecommendations: [],
    };
  },
  computed: {
    ...mapState('admin', ['countryId']),
    ...mapGetters('journal', ['showProductRecommendations']),
  },
  async mounted() {
    if (this.showProductRecommendations && this.appointment?.journal?.id) {
      try {
        const { data } = await EcomApi.getProductRecommendations({
          journalId: this.appointment.journal.id,
          countryId: this.countryId,
        });

        this.productRecommendations = data;
      } catch (error) {
        this.productRecommendations = [];
      }
    }
  },
  methods: {
    toggleInformationSummary() {
      this.showInformationSummary = !this.showInformationSummary;
    },
    toggleJournalSummary() {
      this.showJournalSummary = !this.showJournalSummary;
    },
    toggleReferralsSummary() {
      this.showReferralsSummary = !this.showReferralsSummary;
    },
    toggleFileUploadsSummary() {
      this.showFileUploadsSummary = !this.showFileUploadsSummary;
    },
  },
};
</script>
