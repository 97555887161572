var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "relative flex flex-col rounded",
      class: { "": _vm.showTabActionButton, "opacity-75": _vm.loading },
    },
    [
      _vm.showTabActionButton
        ? _c(
            "animal-record-tab-action-button",
            {
              attrs: { count: _vm.videoConsultationList.length },
              on: {
                click: function ($event) {
                  _vm.historyIsOpen = $event
                },
              },
            },
            [
              _c(
                "span",
                { attrs: { slot: "animalName" }, slot: "animalName" },
                [_vm._v(_vm._s(_vm.animal.name))]
              ),
              _c(
                "span",
                { attrs: { slot: "animalType" }, slot: "animalType" },
                [_vm._v(_vm._s(_vm.animal.animal_type.name))]
              ),
            ]
          )
        : _vm._e(),
      _c("transition", { attrs: { name: "slide-fade" } }, [
        _vm.historyIsOpen
          ? _c(
              "div",
              {
                staticClass: "py-2",
                class: { "px-2": _vm.showTabActionButton },
              },
              [
                _c(
                  "div",
                  { staticClass: "flex flex-col" },
                  [
                    !_vm.videoConsultationList.length &&
                    !_vm.questionList.length &&
                    !_vm.loading
                      ? _c("p", [
                          _vm._v(" No previous FirstVet records found. "),
                        ])
                      : _vm._e(),
                    _vm._l(_vm.videoConsultationList, function (appointment) {
                      return _c(
                        "div",
                        {
                          key: appointment.id,
                          staticClass: "flex w-full flex-col",
                        },
                        [
                          _c(
                            "animal-previous-record-button",
                            {
                              staticClass:
                                "hover:bg-beigeLighter hover:bg-opacity-50",
                              class: {
                                "font-bold":
                                  _vm.consultationRecordsOpen.includes(
                                    appointment.id
                                  ),
                              },
                              attrs: {
                                type: _vm.getServiceChannel(
                                  appointment.booking
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleConsultationRecordSummary(
                                    appointment.id
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "text-sm",
                                  attrs: { slot: "dateOfInteraction" },
                                  slot: "dateOfInteraction",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$formatDate(
                                        appointment.booking.booking_datetime
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "text-left text-sm",
                                  attrs: { slot: "title" },
                                  slot: "title",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.title(appointment)) +
                                      " - " +
                                      _vm._s(_vm.vetName(appointment.booking)) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("transition", { attrs: { name: "slide-fade" } }, [
                            _vm.consultationRecordsOpen.includes(appointment.id)
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "mb-2 rounded border bg-white",
                                  },
                                  [
                                    _c("consultation-summary", {
                                      attrs: {
                                        appointment: appointment,
                                        animal: _vm.animal,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      )
                    }),
                    _vm._l(_vm.questionList, function (question) {
                      return _c(
                        "div",
                        {
                          key: question.id,
                          staticClass: "flex w-full flex-col",
                        },
                        [
                          _c(
                            "animal-previous-record-button",
                            {
                              staticClass:
                                "hover:bg-beigeLighter hover:bg-opacity-50",
                              class: {
                                "font-bold": _vm.questionRecordsOpen.includes(
                                  question.id
                                ),
                              },
                              attrs: { type: "question" },
                              on: {
                                click: function ($event) {
                                  return _vm.openQuestionRecordSummary(
                                    question.id
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "text-sm",
                                  attrs: { slot: "dateOfInteraction" },
                                  slot: "dateOfInteraction",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$formatDate(question.created_at))
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "text-sm",
                                  attrs: { slot: "title" },
                                  slot: "title",
                                },
                                [_vm._v(_vm._s(question.title))]
                              ),
                            ]
                          ),
                          _c("transition", { attrs: { name: "slide-fade" } }, [
                            _vm.questionRecordsOpen.includes(question.id)
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "rounded border bg-white px-2 pt-2 pb-4",
                                  },
                                  [
                                    _c("question-summary", {
                                      attrs: { question: question },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }