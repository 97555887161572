<template>
  <div class="mb-10">
    <title-row> Booking </title-row>
    <display-row :display-value="appointment.id" label-text="Booking ID:" />
    <display-row
      :display-value="createPlatformString(appointment)"
      label-text="Platform:"
    />
    <display-row
      :display-value="
        appointment.booking && appointment.booking.veterinary
          ? appointment.booking.veterinary.display_name
          : 'No vet assigned'
      "
      label-text="Veterinarian:"
    />

    <meeting-display-rows :appointment="appointment" />

    <display-row
      :display-value="appointment.booking.created_at"
      label-text="Booking date:"
    />
  </div>
</template>

<script>
import MeetingDisplayRows from './booking_info_rows/MeetingDisplayRows.vue';

export default {
  components: { MeetingDisplayRows },
  props: {
    appointment: {
      type: Object,
      required: true,
    },
  },
  methods: {
    createPlatformString(appointment) {
      return (
        (appointment.device ?? '') +
        ' ' +
        (appointment.funnel ?? '') +
        ' ' +
        (appointment.version ?? '')
      );
    },
  },
};
</script>

<style scoped lang="postcss">
a {
  @apply underline;
}
</style>
