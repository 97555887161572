var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loadedImages.length
        ? _c("div", { staticClass: "w-full my-4" }, [
            _c(
              "div",
              {
                staticClass: "flex flex-wrap gap-y-2 gap-x-2",
                attrs: { id: "images" },
              },
              _vm._l(_vm.loadedImages, function (image, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "w-1/4 relative border-2 shadow cursor-pointer appointment-image",
                    class: {
                      "border-primary": _vm.selectedImages.includes(
                        image.fileName
                      ),
                    },
                    on: {
                      click: function ($event) {
                        _vm.gallery
                          ? _vm.showImage(index)
                          : _vm.handleSelectedImage(image)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "object-cover",
                      attrs: { src: image.src || image },
                    }),
                    !_vm.gallery
                      ? _c(
                          "div",
                          [
                            _vm.selectedImages.includes(image.fileName)
                              ? _c("fv-icon", {
                                  staticClass: "absolute text-primary",
                                  staticStyle: {
                                    left: ".4rem",
                                    bottom: ".4rem",
                                  },
                                  attrs: { icon: "check-mark-alt" },
                                })
                              : _c("fv-icon", {
                                  staticClass: "absolute text-white",
                                  staticStyle: {
                                    left: ".3rem",
                                    bottom: ".3rem",
                                  },
                                  attrs: { icon: "add" },
                                }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _c(
        "base-modal",
        {
          attrs: { show: _vm.showImageModal },
          on: {
            close: function ($event) {
              ;(_vm.showImageModal = false), (_vm.selectedImage = 0)
            },
          },
        },
        [
          _c("div", { staticClass: "relative" }, [
            _c("img", {
              attrs: { src: _vm.loadedImages[_vm.selectedIndex].src },
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loadedImages.length > 1,
                    expression: "loadedImages.length > 1",
                  },
                ],
                staticClass: "flex justify-between absolute w-full px-4",
                staticStyle: { top: "47%" },
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "hover:opacity-75 cursor-pointer text-white",
                  class: { invisible: _vm.selectedIndex === 0 },
                  attrs: { icon: "angle-left", size: "3x" },
                  on: {
                    click: function ($event) {
                      _vm.selectedIndex = _vm.selectedIndex - 1
                    },
                  },
                }),
                _c("font-awesome-icon", {
                  staticClass: "hover:opacity-75 cursor-pointer text-white",
                  class: {
                    invisible:
                      _vm.selectedIndex === _vm.loadedImages.length - 1,
                  },
                  attrs: { icon: "angle-right", size: "3x" },
                  on: {
                    click: function ($event) {
                      _vm.selectedIndex = _vm.selectedIndex + 1
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }