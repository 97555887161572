var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "display-row",
    { attrs: { "label-text": _vm.showLabel ? "Insurance:" : "" } },
    [_vm._v(" " + _vm._s(_vm.animalInsurance) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }