var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col space-y-6" }, [
    _c(
      "div",
      { staticClass: "flex flex-col space-y-2" },
      [
        _c("title-row", [_vm._v("Question")]),
        _c("nl2br", {
          staticClass: "prose",
          attrs: { tag: "p", text: _vm.question.original_question },
        }),
      ],
      1
    ),
    _vm.question.answer
      ? _c(
          "div",
          { staticClass: "flex flex-col space-y-2" },
          [
            _c("title-row", [_vm._v("Answer")]),
            _c("div", {
              staticClass: "prose",
              domProps: { innerHTML: _vm._s(_vm.question.answer) },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.question.answer_private
      ? _c(
          "div",
          { staticClass: "flex flex-col space-y-2" },
          [
            _c("title-row", [_vm._v("Private answer")]),
            _c("div", {
              staticClass: "prose",
              domProps: { innerHTML: _vm._s(_vm.question.answer_private) },
            }),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "flex flex-col space-y-2" },
      [
        _c("title-row", [_vm._v("Answered by")]),
        _c("p", [
          _vm._v(
            " " +
              _vm._s(
                _vm.question.veterinary
                  ? _vm.question.veterinary.display_name
                  : "Not reply posted."
              )
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }