<template>
  <div class="mb-8">
    <div class="mb-4">
      <title-row v-if="displayTitle"> Uploaded files </title-row>
      <div v-if="uploads.length">
        <div
          v-for="upload in uploads"
          :key="upload.id"
          class="border-top flex items-center p-3 hover:bg-gray-200"
        >
          <button
            class="focus:outline-none flex w-full items-center text-primary"
            :class="{
              'cursor-default opacity-50':
                isDeletingFile(upload.id) || isFetching(upload.id),
            }"
            target="_blank"
            @click="getUploadedFile(upload.id)"
          >
            <base-spinner v-if="isFetching(upload.id)" class="mr-3" />
            <font-awesome-icon
              v-else
              class="mr-3"
              :icon="getFileIcon(upload.path)"
            />
            <span class="flex flex-col text-left">
              <small class="text-gray-600">
                {{ formatDate(upload.created_at) }}
              </small>
              <small v-if="upload.user" class="text-gray-600">
                {{ upload.user.display_name }}
              </small>
              <span class="underline">{{ upload.name }}</span>
            </span>
          </button>

          <button
            :disabled="isFetching(upload.id) || isDeletingFile(upload.id)"
            :class="{
              'cursor-not-allowed opacity-50':
                isDeletingFile(upload.id) || isFetching(upload.id),
            }"
            @click="(showConfirmationModal = true), (selectedId = upload.id)"
          >
            <fv-icon
              v-if="showDeleteButton && !isDeletingFile(upload.id)"
              v-tooltip="'Delete file'"
              class="text-red-800 hover:text-red-600"
              icon="delete"
            />
            <base-spinner v-if="isDeletingFile(upload.id)" />
          </button>
        </div>
      </div>
      <div v-else class="pt-2">
        <p>No uploaded files</p>
      </div>
    </div>
    <div v-if="showUploadButton" class="mb-8 w-full">
      <p class="mb-5 border-b pb-1 text-base font-semibold text-black">
        Upload files
      </p>
      <div
        v-if="error"
        class="mb-3 flex items-center space-x-3 rounded border border-red-200 bg-red-100 p-4 text-red-900"
      >
        <span><fv-icon icon="warning" /></span>
        <span>{{ error }}</span>
      </div>
      <div
        class="dropbox hover:bg-gray-50"
        :class="{
          'bg-opacity-75 hover:bg-gray-50': loading,
          'bg-beigeLight': active,
          'bg-beigeLighter': !active,
        }"
        @dragover="fileDrag('in')"
        @drop="fileDrag"
        @dragleave="fileDrag"
      >
        <form
          class="flex items-center justify-center"
          enctype="multipart/form-data"
          novalidate
        >
          <input
            type="file"
            :accept="extensions.join(',')"
            class="absolute top-0 left-0 h-full w-full cursor-pointer opacity-0"
            multiple
            @change="uploadFiles"
          />
          <div class="flex flex-col items-center">
            <base-spinner v-if="loading" />
            <fv-icon v-if="!loading" icon="upload-40" size="xl" />
            <p class="mb-1">
              <span v-if="!loading">
                {{
                  active
                    ? 'Drop your files here'
                    : 'Drag and drop your files here '
                }}
              </span>
              <span v-else> Uploading ... </span>
            </p>
            <small v-if="!loading" class="mb-0">
              (<span v-for="(ext, index) in extensions" :key="ext">
                {{ index !== 0 ? ', ' : '' }}
                <em>{{ ext }}</em> </span
              >)
            </small>
          </div>
        </form>
      </div>
    </div>

    <base-modal :show="showConfirmationModal">
      <slot>
        <h2 class="mt-2 mb-4 text-center text-xl">
          <span class="font-bold">Delete file </span>
        </h2>
        <div class="mb-2 flex items-center">
          <fv-icon icon="warning" class="mx-3 text-red-800" />
          <p class="p-2">Are you sure you want to delete this file?</p>
        </div>
      </slot>
      <div slot="buttons" class="flex w-full justify-end space-x-2 p-2">
        <base-button color="cancel" @click="showConfirmationModal = false">
          Cancel
        </base-button>
        <base-button
          :loading="loading"
          color="primary"
          :disabled="loading"
          @click="deleteFile(selectedId), (showConfirmationModal = false)"
        >
          Yes, delete file
        </base-button>
      </div>
    </base-modal>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import AppointmentUploadsApi from '@/api/modules/appointment-uploads';
import { format } from 'date-fns';

export default {
  name: 'UploadFileComponent',
  props: {
    appointmentId: {
      type: Number,
      default: -1,
    },
    uploads: {
      type: Array,
      default() {
        return [];
      },
    },
    extensions: {
      type: Array,
      default() {
        return [];
      },
    },
    showUploadButton: {
      type: Boolean,
      default: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    displayTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      active: false,
      loading: false,
      isDeleting: [],
      showConfirmationModal: false,
      selectedId: null,
      getFile: false,
      error: '',
    };
  },
  mounted() {},
  methods: {
    ...mapMutations('appointment', ['setAppointmentUploads']),

    getUploadedFile(id) {
      this.getFile = true;
      if (id === this.selectedId) return;
      this.selectedId = id;
      AppointmentUploadsApi.getAppointmentUpload(id)
        .then(res => {
          const url = res.data;
          window.open(url, '_blank');
        })
        .catch(e => {
          this.$notify({
            group: 'error',
            title: 'Could not fetch image',
            message: e.message,
          });
        })
        .finally(() => {
          this.getFile = false;
          this.selectedId = null;
        });
    },
    uploadFiles(event) {
      this.error = '';
      this.loading = true;
      const inputElement = event.target;
      const files = event.target.files || event.dataTransfer.files;
      const result = Array.from(files).map(file => {
        return AppointmentUploadsApi.upload(this.appointmentId, file)
          .then(res => {
            if (!res) {
              throw Error('Couldnt upload file');
            }
            this.setAppointmentUploads([...this.uploads, res.data]);
          })
          .catch(e => {
            this.error = e.message;
          });
      });
      Promise.all(result).finally(() => {
        this.loading = false;
        inputElement.value = '';
      });
    },
    deleteFile(fileId) {
      if (this.isDeletingFile(fileId)) {
        return;
      }

      this.isDeleting.push(fileId);
      AppointmentUploadsApi.deleteAppointmentUpload(fileId)
        .then(() => {
          this.setAppointmentUploads(
            this.uploads.filter(upload => upload.id !== fileId)
          );
        })
        .catch(e => {
          this.$notify({
            group: 'error',
            title: 'Something went wrong',
            message: e.message,
          });
        })
        .finally(() => {
          this.isDeleting = this.isDeleting.filter(id => id !== fileId);
        });
      this.selectedId = null;
    },
    fileDrag(type) {
      this.active = type === 'in' || false;
    },
    isDeletingFile(id) {
      return this.isDeleting.indexOf(id) > -1;
    },
    isFetching(id) {
      return id === this.selectedId && this.getFile;
    },
    formatDate(date) {
      return format(new Date(date), 'yyyy-MM-dd HH:mm');
    },
    getFileIcon(path) {
      const ext = path.split('.').pop();

      switch (ext) {
        case 'pdf':
          return 'file-pdf';
        case 'doc':
        case 'docx':
          return 'file-word';
        case 'rtf':
          return 'file-alt';
        default:
          return 'file';
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.dropbox {
  @apply relative cursor-pointer rounded-lg border border-dashed border-gray-800 text-gray-800;
  min-height: 130px;
}
form {
  height: 130px;
}
</style>
