<template>
  <button
    class="flex w-full justify-between py-3 px-2 
          hover:text-black rounded 
          focus:outline-none"
    @click="$emit('click')"
  >
    <p class="flex space-x-3 items-center text-base mb-2">
      <consultation-type-badge :type="type" />
      <slot name="dateOfInteraction" />
      <slot name="title" />
    </p>
    <fv-icon icon="chevron-down" />
  </button>
</template>

<script>
import ConsultationTypeBadge from '@/UI/badges/ConsultationTypeBadge.vue';

export default {
  components: {
    ConsultationTypeBadge,
  },
  props: {
    type: {
      type: String,
      default: 'video',
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>
