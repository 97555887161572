var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-8" },
    [
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _vm.displayTitle
            ? _c("title-row", [_vm._v(" Uploaded files ")])
            : _vm._e(),
          _vm.uploads.length
            ? _c(
                "div",
                _vm._l(_vm.uploads, function (upload) {
                  return _c(
                    "div",
                    {
                      key: upload.id,
                      staticClass:
                        "border-top flex items-center p-3 hover:bg-gray-200",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "focus:outline-none flex w-full items-center text-primary",
                          class: {
                            "cursor-default opacity-50":
                              _vm.isDeletingFile(upload.id) ||
                              _vm.isFetching(upload.id),
                          },
                          attrs: { target: "_blank" },
                          on: {
                            click: function ($event) {
                              return _vm.getUploadedFile(upload.id)
                            },
                          },
                        },
                        [
                          _vm.isFetching(upload.id)
                            ? _c("base-spinner", { staticClass: "mr-3" })
                            : _c("font-awesome-icon", {
                                staticClass: "mr-3",
                                attrs: { icon: _vm.getFileIcon(upload.path) },
                              }),
                          _c(
                            "span",
                            { staticClass: "flex flex-col text-left" },
                            [
                              _c("small", { staticClass: "text-gray-600" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formatDate(upload.created_at)) +
                                    " "
                                ),
                              ]),
                              upload.user
                                ? _c(
                                    "small",
                                    { staticClass: "text-gray-600" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(upload.user.display_name) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("span", { staticClass: "underline" }, [
                                _vm._v(_vm._s(upload.name)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "button",
                        {
                          class: {
                            "cursor-not-allowed opacity-50":
                              _vm.isDeletingFile(upload.id) ||
                              _vm.isFetching(upload.id),
                          },
                          attrs: {
                            disabled:
                              _vm.isFetching(upload.id) ||
                              _vm.isDeletingFile(upload.id),
                          },
                          on: {
                            click: function ($event) {
                              ;(_vm.showConfirmationModal = true),
                                (_vm.selectedId = upload.id)
                            },
                          },
                        },
                        [
                          _vm.showDeleteButton && !_vm.isDeletingFile(upload.id)
                            ? _c("fv-icon", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: "Delete file",
                                    expression: "'Delete file'",
                                  },
                                ],
                                staticClass: "text-red-800 hover:text-red-600",
                                attrs: { icon: "delete" },
                              })
                            : _vm._e(),
                          _vm.isDeletingFile(upload.id)
                            ? _c("base-spinner")
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              )
            : _c("div", { staticClass: "pt-2" }, [
                _c("p", [_vm._v("No uploaded files")]),
              ]),
        ],
        1
      ),
      _vm.showUploadButton
        ? _c("div", { staticClass: "mb-8 w-full" }, [
            _c(
              "p",
              {
                staticClass:
                  "mb-5 border-b pb-1 text-base font-semibold text-black",
              },
              [_vm._v(" Upload files ")]
            ),
            _vm.error
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mb-3 flex items-center space-x-3 rounded border border-red-200 bg-red-100 p-4 text-red-900",
                  },
                  [
                    _c(
                      "span",
                      [_c("fv-icon", { attrs: { icon: "warning" } })],
                      1
                    ),
                    _c("span", [_vm._v(_vm._s(_vm.error))]),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "dropbox hover:bg-gray-50",
                class: {
                  "bg-opacity-75 hover:bg-gray-50": _vm.loading,
                  "bg-beigeLight": _vm.active,
                  "bg-beigeLighter": !_vm.active,
                },
                on: {
                  dragover: function ($event) {
                    return _vm.fileDrag("in")
                  },
                  drop: _vm.fileDrag,
                  dragleave: _vm.fileDrag,
                },
              },
              [
                _c(
                  "form",
                  {
                    staticClass: "flex items-center justify-center",
                    attrs: { enctype: "multipart/form-data", novalidate: "" },
                  },
                  [
                    _c("input", {
                      staticClass:
                        "absolute top-0 left-0 h-full w-full cursor-pointer opacity-0",
                      attrs: {
                        type: "file",
                        accept: _vm.extensions.join(","),
                        multiple: "",
                      },
                      on: { change: _vm.uploadFiles },
                    }),
                    _c(
                      "div",
                      { staticClass: "flex flex-col items-center" },
                      [
                        _vm.loading ? _c("base-spinner") : _vm._e(),
                        !_vm.loading
                          ? _c("fv-icon", {
                              attrs: { icon: "upload-40", size: "xl" },
                            })
                          : _vm._e(),
                        _c("p", { staticClass: "mb-1" }, [
                          !_vm.loading
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.active
                                        ? "Drop your files here"
                                        : "Drag and drop your files here "
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [_vm._v(" Uploading ... ")]),
                        ]),
                        !_vm.loading
                          ? _c(
                              "small",
                              { staticClass: "mb-0" },
                              [
                                _vm._v(" ("),
                                _vm._l(_vm.extensions, function (ext, index) {
                                  return _c("span", { key: ext }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(index !== 0 ? ", " : "") +
                                        " "
                                    ),
                                    _c("em", [_vm._v(_vm._s(ext))]),
                                  ])
                                }),
                                _vm._v(") "),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _c(
        "base-modal",
        { attrs: { show: _vm.showConfirmationModal } },
        [
          _vm._t("default", function () {
            return [
              _c("h2", { staticClass: "mt-2 mb-4 text-center text-xl" }, [
                _c("span", { staticClass: "font-bold" }, [
                  _vm._v("Delete file "),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "mb-2 flex items-center" },
                [
                  _c("fv-icon", {
                    staticClass: "mx-3 text-red-800",
                    attrs: { icon: "warning" },
                  }),
                  _c("p", { staticClass: "p-2" }, [
                    _vm._v("Are you sure you want to delete this file?"),
                  ]),
                ],
                1
              ),
            ]
          }),
          _c(
            "div",
            {
              staticClass: "flex w-full justify-end space-x-2 p-2",
              attrs: { slot: "buttons" },
              slot: "buttons",
            },
            [
              _c(
                "base-button",
                {
                  attrs: { color: "cancel" },
                  on: {
                    click: function ($event) {
                      _vm.showConfirmationModal = false
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "base-button",
                {
                  attrs: {
                    loading: _vm.loading,
                    color: "primary",
                    disabled: _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      _vm.deleteFile(_vm.selectedId),
                        (_vm.showConfirmationModal = false)
                    },
                  },
                },
                [_vm._v(" Yes, delete file ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }