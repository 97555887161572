var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass:
        "flex w-full px-2 py-4 space-x-4 items-center rounded\n      hover:bg-beigeLighter hover:bg-opacity-30\n      focus:outline-none",
      class: { underline: _vm.isOpen, "cursor-default": !_vm.count },
      attrs: { disabled: !_vm.count },
      on: { click: _vm.handleActionButtonClick },
    },
    [
      _c(
        "span",
        {},
        [
          _vm._t("animalName"),
          _c(
            "span",
            { staticClass: "text-sm ml-2 italic" },
            [_vm._t("animalType")],
            2
          ),
        ],
        2
      ),
      _vm.count
        ? _c(
            "span",
            {
              staticClass:
                "text-xs font-semibold rounded-full w-5 h-5 \n    bg-primary border-primary-darker border-2 text-white flex items-center justify-center",
            },
            [_vm._v(" " + _vm._s(_vm.count) + " ")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }