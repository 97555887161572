var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col mb-6 space-y-4" },
    _vm._l(_vm.referralList, function (referral) {
      return _c(
        "div",
        {
          key: referral.id,
          staticClass:
            "flex flex-col w-full border py-3 px-3 space-y-4 rounded shadow-sm bg-white",
        },
        [
          _c("div", { staticClass: "flex flex-col space-y-4" }, [
            referral.clinic
              ? _c("div", { staticClass: "space-y-4" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "font-bold flex justify-between items-center",
                    },
                    [
                      _c(
                        "p",
                        { staticClass: " font-display tracking-wide text-lg" },
                        [_vm._v(" " + _vm._s(referral.clinic.name) + " ")]
                      ),
                      _c("p", { staticClass: "text-xs font-normal" }, [
                        _vm._v(" " + _vm._s(referral.created_at) + " "),
                      ]),
                    ]
                  ),
                  _c("div", [
                    _c("p", [
                      _c("span", { staticClass: "font-semibold" }, [
                        _vm._v("Email: "),
                      ]),
                      referral.email
                        ? _c("span", [_vm._v(_vm._s(referral.email))])
                        : _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  referral.clinic.referral_email
                                    ? referral.clinic.referral_email
                                    : referral.clinic.email
                                ) +
                                " "
                            ),
                          ]),
                    ]),
                    _c("p", {}, [
                      _c("span", { staticClass: "font-semibold" }, [
                        _vm._v("Tel:"),
                      ]),
                      _vm._v(" " + _vm._s(referral.clinic.phone) + " "),
                    ]),
                    _c("p", [
                      _c("span", { staticClass: "font-semibold" }, [
                        _vm._v("Address:"),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(referral.clinic.adress) +
                          " " +
                          _vm._s(referral.clinic.postcode) +
                          " " +
                          _vm._s(referral.clinic.city) +
                          " "
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            !referral.clinic && referral.email
              ? _c("div", [
                  _c("div", { staticClass: "font-bold flex justify-between" }, [
                    _c("p", [_vm._v(" " + _vm._s(referral.email) + " ")]),
                    _c("p", { staticClass: "text-xs font-normal" }, [
                      _vm._v(" " + _vm._s(referral.created_at) + " "),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
          referral.reason
            ? _c("div", [
                _c("p", { staticClass: "font-bold mb-2" }, [
                  _vm._v(" Reason for clinic visit / clinical diagnosis: "),
                ]),
                _c("p", [_vm._v(" " + _vm._s(referral.reason) + " ")]),
              ])
            : _vm._e(),
          referral.referral_contact_option || referral.contact
            ? _c("div", [
                _c("p", { staticClass: "font-bold mb-2" }, [
                  _vm._v(" Further contact: "),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        referral.referral_contact_option
                          ? referral.referral_contact_option.name
                          : referral.contact
                      ) +
                      " "
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }