<template>
  <li
    :class="{ '': showTabActionButton, 'opacity-75': loading }"
    class="relative flex flex-col rounded"
  >
    <animal-record-tab-action-button
      v-if="showTabActionButton"
      :count="videoConsultationList.length"
      @click="historyIsOpen = $event"
    >
      <span slot="animalName">{{ animal.name }}</span>
      <span slot="animalType">{{ animal.animal_type.name }}</span>
    </animal-record-tab-action-button>

    <transition name="slide-fade">
      <div
        v-if="historyIsOpen"
        class="py-2"
        :class="{ 'px-2': showTabActionButton }"
      >
        <div class="flex flex-col">
          <p
            v-if="
              !videoConsultationList.length && !questionList.length && !loading
            "
          >
            No previous FirstVet records found.
          </p>

          <div
            v-for="appointment in videoConsultationList"
            :key="appointment.id"
            class="flex w-full flex-col"
          >
            <animal-previous-record-button
              class="hover:bg-beigeLighter hover:bg-opacity-50"
              :class="{
                'font-bold': consultationRecordsOpen.includes(appointment.id),
              }"
              :type="getServiceChannel(appointment.booking)"
              @click="toggleConsultationRecordSummary(appointment.id)"
            >
              <span slot="dateOfInteraction" class="text-sm">{{
                $formatDate(appointment.booking.booking_datetime)
              }}</span>
              <span slot="title" class="text-left text-sm"
                >{{ title(appointment) }} -
                {{ vetName(appointment.booking) }}
              </span>
            </animal-previous-record-button>
            <transition name="slide-fade">
              <div
                v-if="consultationRecordsOpen.includes(appointment.id)"
                class="mb-2 rounded border bg-white"
              >
                <consultation-summary
                  :appointment="appointment"
                  :animal="animal"
                />
              </div>
            </transition>
          </div>

          <div
            v-for="question in questionList"
            :key="question.id"
            class="flex w-full flex-col"
          >
            <animal-previous-record-button
              class="hover:bg-beigeLighter hover:bg-opacity-50"
              :class="{
                'font-bold': questionRecordsOpen.includes(question.id),
              }"
              type="question"
              @click="openQuestionRecordSummary(question.id)"
            >
              <span slot="dateOfInteraction" class="text-sm">{{
                $formatDate(question.created_at)
              }}</span>
              <span slot="title" class="text-sm">{{ question.title }}</span>
            </animal-previous-record-button>

            <transition name="slide-fade">
              <div
                v-if="questionRecordsOpen.includes(question.id)"
                class="rounded border bg-white px-2 pt-2 pb-4"
              >
                <question-summary :question="question" />
              </div>
            </transition>
          </div>
        </div>
      </div>
    </transition>
  </li>
</template>

<script>
import ConsultationSummary from '@/components/summaries/ConsultationSummary.vue';
import QuestionSummary from '@/components/appointments/QuestionSummary.vue';
import AnimalRecordTabActionButton from '@/components/animal/previous-records/AnimalRecordTabActionButton.vue';
import AnimalPreviousRecordButton from '@/components/animal/previous-records/AnimalPreviousRecordButton.vue';
import { mapActions } from 'vuex';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export default {
  components: {
    AnimalRecordTabActionButton,
    AnimalPreviousRecordButton,
    QuestionSummary,
    ConsultationSummary,
  },
  props: {
    animal: {
      type: Object,
      required: true,
    },
    openHistoryOnMount: {
      type: Boolean,
      default: false,
    },
    showTabActionButton: {
      type: Boolean,
      default: true,
    },
    currentAppointmentId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      historyIsOpen: this.$props.openHistoryOnMount,
      consultationRecordsOpen: [],
      questionRecordsOpen: [],
      appointments: [],
      questions: [],
      loading: false,
    };
  },

  computed: {
    videoConsultationList() {
      if (this.appointments.length) {
        return this.appointments
          .filter(a => a.booking_id)
          .filter(a => a.id !== this.currentAppointmentId)
          .sort((appA, appB) => {
            return new Date(appB.order_date) - new Date(appA.order_date);
          });
      }
      return this.appointments;
    },

    questionList() {
      if (this.questions.length) {
        const qs = this.questions;
        return qs.sort((appA, appB) => {
          return new Date(appB.created_at) - new Date(appA.created_at);
        });
      }
      return this.questions;
    },
  },

  mounted() {
    this.getHistory();
  },

  methods: {
    ...mapActions('animal', ['fetchHistory']),

    async getHistory() {
      this.loading = true;
      this.error = false;
      const { data } = await this.fetchHistory(this.animal.id).catch(() => {
        this.error = true;
        this.loading = false;
      });

      this.questions = data.questions;
      this.appointments = data.appointments;

      this.loading = false;
    },

    toggleConsultationRecordSummary(appointmentId) {
      if (this.consultationRecordsOpen.includes(appointmentId)) {
        const position = this.consultationRecordsOpen.indexOf(appointmentId);
        this.consultationRecordsOpen.splice(position, 1);
        return;
      }
      this.consultationRecordsOpen.push(appointmentId);
    },

    openQuestionRecordSummary(questionId) {
      if (this.questionRecordsOpen.includes(questionId)) {
        const position = this.questionRecordsOpen.indexOf(questionId);
        this.questionRecordsOpen.splice(position, 1);
        return;
      }
      this.questionRecordsOpen.push(questionId);
    },

    closeAllRecords() {
      this.consultationRecordsOpen = [];
      this.questionRecordsOpen = [];
    },

    vetName(booking) {
      if (booking && booking.veterinary) {
        return (
          booking.veterinary.display_name ||
          `${booking.veterinary.first_name} ${booking.veterinary.last_name}`
        );
      }
      return 'No vet assigned';
    },

    title(appointment) {
      if (appointment?.booking) {
        const start = new Date(appointment.booking?.booking_datetime).getTime();
        const now = new Date().getTime();
        const diff = start - now;

        if (diff > 0) {
          return `Upcoming booking (${this.formatTime(appointment.booking)})`;
        }
      }
      if (appointment?.appointment_type?.name) {
        return appointment.appointment_type.name;
      }
      if (appointment?.journal && !appointment.journal.status) {
        return 'Unfinished journal';
      }
      if (!appointment?.journal) {
        return 'Missing journal';
      }
      return '';
    },

    getServiceChannel(booking) {
      return booking?.service?.key === 'veterinary_chat' ? 'chat' : 'video';
    },

    formatTime(booking) {
      return format(
        zonedTimeToUtc(booking.booking_datetime, booking.country.timezone),
        'HH:mm'
      );
    },
  },
};
</script>
