<template>
  <display-row :label-text="showLabel ? 'Insurance:' : ''">
    {{ animalInsurance }} 
  </display-row>
</template>

<script>
export default {
  props: {
    animal: {
      type: Object,
      required: true,
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    isInsuranceNameLoaded() {
      return this.animal.insurance_type && this.animal.insurance_type.name;
    },

    animalInsurance() {
      const noInsuranceStr = 'No insurance company information';
      return this.isInsuranceNameLoaded
        ? this.animal.insurance_type.name
        : noInsuranceStr;
    },
  },
};
</script>
