<template>
  <div>
    <display-row label-text="Meeting time:">
      {{ formattedDate }} {{ formattedTime }} 
      ({{ appointment.booking.slot && appointment.booking.slot.duration ? 
        appointment.booking.slot.duration + appointment.booking.slot.margin : '?' }} min)
    </display-row>
    <display-row label-text="Meeting:">
      {{ serviceName }} ({{ appointmentStatus }})
    </display-row>
  </div>
</template>

<script>
import { capitalize } from 'lodash';
import appointmentStatuses from '@/config/appointment-statuses';

const NOT_YET_LOADED_PLACEHOLDER = '-';

export default {
  props: {
    appointment: {
      type: Object,
      required: true,
    },
  },

  computed: {
    hasLoadedAppointment() {
      return (
        this.appointment &&
        this.appointment.booking &&
        this.appointment.booking.service
      );
    },

    appointmentStatus() {
      const isFree =
        String(this.appointment.free).includes('/200') ||
        this.appointment.free === null;
      const freeString = isFree ? 'free' : this.appointment.free;
      const meetingStr = `${freeString} -
      					  ${String(appointmentStatuses[this.appointment.status]).toLowerCase()}`;
      return meetingStr;
    },

    formattedTime() {
      return this.hasLoadedAppointment
        ? this.$formatTime(this.appointment.booking.booking_datetime)
        : NOT_YET_LOADED_PLACEHOLDER;
    },

    formattedDate() {
      return this.hasLoadedAppointment
        ? this.$formatDate(this.appointment.booking.booking_datetime)
        : NOT_YET_LOADED_PLACEHOLDER;
    },

    serviceName() {
      const isServiceNameLoadedAndDefined =
        this.hasLoadedAppointment && this.appointment.booking.service.name;

      return isServiceNameLoadedAndDefined
        ? capitalize(this.appointment.booking.service.name)
        : NOT_YET_LOADED_PLACEHOLDER;
    },
  },
};
</script>
