var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("title-row", [_vm._v(" Animal ")]),
      _c("ValidationProvider", {
        attrs: { rules: "required", name: "animal name" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                (_vm.animal && _vm.animal.name) || _vm.isInEditMode
                  ? _c(
                      "editable-row",
                      {
                        attrs: {
                          "is-inner-content-visible": _vm.isInEditMode,
                          "label-text": "Name:",
                          "display-value": _vm.animal.name,
                          error: errors[0],
                        },
                      },
                      [
                        _c("base-input", {
                          staticClass: "text-sm",
                          attrs: { name: "animal name" },
                          model: {
                            value: _vm.animal.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.animal, "name", $$v)
                            },
                            expression: "animal.name",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "editable-row",
        {
          attrs: {
            "is-inner-content-visible": _vm.isInEditMode,
            "label-text": "Type:",
            "display-value":
              _vm.animalType && _vm.animalType.name ? _vm.animalType.name : "",
          },
        },
        [
          _c("v-select", {
            staticClass: "text-sm",
            attrs: {
              options: _vm.animalTypesInState,
              clearable: false,
              loading: !_vm.animalTypesInState.length,
              label: "name",
            },
            model: {
              value: _vm.animalType,
              callback: function ($$v) {
                _vm.animalType = $$v
              },
              expression: "animalType",
            },
          }),
        ],
        1
      ),
      _c(
        "editable-row",
        {
          attrs: {
            "is-inner-content-visible": _vm.isInEditMode,
            "label-text": "Breed:",
            "display-value": _vm.animal.race || "Unknown",
          },
        },
        [
          _c("base-input", {
            staticClass: "text-sm",
            attrs: { name: "animal race" },
            model: {
              value: _vm.animal.race,
              callback: function ($$v) {
                _vm.$set(_vm.animal, "race", $$v)
              },
              expression: "animal.race",
            },
          }),
        ],
        1
      ),
      _c(
        "editable-row",
        {
          staticClass: "datepicker-wrapper",
          attrs: {
            "is-inner-content-visible": _vm.isInEditMode,
            "label-text": "Date of Birth:",
            "display-value": _vm.animalBirthDate,
          },
        },
        [
          _c("Datepicker", {
            attrs: {
              name: "animal birth date",
              "input-class":
                "text-sm border border-gray-400 py-2 px-2 leading-tight rounded\n\t\t\t\t\t w-full focus:outline-none focus:border-gray-600",
              format: "yyyy-MM-dd",
              "monday-first": true,
            },
            model: {
              value: _vm.animalBirthDate,
              callback: function ($$v) {
                _vm.animalBirthDate = $$v
              },
              expression: "animalBirthDate",
            },
          }),
        ],
        1
      ),
      _c(
        "editable-row",
        {
          attrs: {
            "is-inner-content-visible": _vm.isInEditMode,
            "label-text": "Gender:",
            "display-value": _vm.animalGender,
          },
        },
        [
          _c("v-select", {
            staticClass: "text-sm",
            attrs: {
              name: "animal gender",
              options: _vm.genderOptionsInState,
              clearable: false,
              loading: !_vm.genderOptionsInState.length,
              label: "name",
            },
            model: {
              value: _vm.animalGender,
              callback: function ($$v) {
                _vm.animalGender = $$v
              },
              expression: "animalGender",
            },
          }),
        ],
        1
      ),
      _c("ValidationProvider", {
        attrs: {
          rules: { regex: /^\d+((,|\.){0,1}\d*){0,1}$/ },
          name: "animal weight",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                _c(
                  "editable-row",
                  {
                    attrs: {
                      "is-inner-content-visible": _vm.isInEditMode,
                      "label-text": "Weight:",
                      "display-value": _vm.getWeightStringFromAppointment(
                        _vm.appointment
                      ),
                      error: errors[0],
                      "data-testid": "animal-weight",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("base-input", {
                          staticClass: "weightInput mr-1 inline-block text-sm",
                          attrs: { "is-inline": true, name: "animal weight" },
                          model: {
                            value: _vm.appointment.weight,
                            callback: function ($$v) {
                              _vm.$set(_vm.appointment, "weight", $$v)
                            },
                            expression: "appointment.weight",
                          },
                        }),
                        _c("v-select", {
                          class: ["weightSelect", "text-sm"],
                          attrs: {
                            options: _vm.weightUnitOptions,
                            loading: !_vm.weightUnitOptions.length,
                            clearable: false,
                            "data-testid": "weight-unit",
                          },
                          model: {
                            value: _vm.weightUnit,
                            callback: function ($$v) {
                              _vm.weightUnit = $$v
                            },
                            expression: "weightUnit",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      (_vm.animal.description && _vm.animal.description.trim() !== "") ||
      _vm.isInEditMode
        ? _c(
            "editable-row",
            {
              attrs: {
                "display-value": _vm.animal.description,
                "label-text": "Animal Description:",
                "is-inner-content-visible": _vm.isInEditMode,
                "is-label-never-inline": true,
              },
            },
            [
              _c("textarea-input", {
                attrs: { name: "animal description" },
                model: {
                  value: _vm.animal.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.animal, "description", $$v)
                  },
                  expression: "animal.description",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }