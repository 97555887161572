import { render, staticRenderFns } from "./BookingInformationAnimalSection.vue?vue&type=template&id=17407940&scoped=true&"
import script from "./BookingInformationAnimalSection.vue?vue&type=script&lang=js&"
export * from "./BookingInformationAnimalSection.vue?vue&type=script&lang=js&"
import style0 from "./BookingInformationAnimalSection.vue?vue&type=style&index=0&id=17407940&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17407940",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/firstvet/vetpanel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('17407940')) {
      api.createRecord('17407940', component.options)
    } else {
      api.reload('17407940', component.options)
    }
    module.hot.accept("./BookingInformationAnimalSection.vue?vue&type=template&id=17407940&scoped=true&", function () {
      api.rerender('17407940', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/booking/BookingInformationAnimalSection.vue"
export default component.exports