import { render, staticRenderFns } from "./ImageGallery.vue?vue&type=template&id=47f9e6ee&"
import script from "./ImageGallery.vue?vue&type=script&lang=js&"
export * from "./ImageGallery.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/firstvet/vetpanel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47f9e6ee')) {
      api.createRecord('47f9e6ee', component.options)
    } else {
      api.reload('47f9e6ee', component.options)
    }
    module.hot.accept("./ImageGallery.vue?vue&type=template&id=47f9e6ee&", function () {
      api.rerender('47f9e6ee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/images/ImageGallery.vue"
export default component.exports