var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("title-row", [_vm._v(" Customer ")]),
      _c("display-row", {
        attrs: {
          "display-value": _vm.appointment.user
            ? _vm.appointment.user.display_name
            : "",
          "label-text": "Name:",
        },
      }),
      _c("display-row", {
        attrs: {
          "display-value": _vm.appointment.user.mobile_phone_formatted,
          "has-copy-button": true,
          "label-text": "Phone number:",
        },
      }),
      _c("display-row", {
        attrs: {
          "display-value": _vm.appointment.user.email,
          "label-text": "Email:",
        },
      }),
      _vm.appointment.user.us_state && _vm.appointment.user.us_state.state
        ? _c("display-row", {
            attrs: {
              "display-value": _vm.appointment.user.us_state.state,
              "label-text": "State:",
            },
          })
        : _vm._e(),
      _vm.userIsInSweden
        ? _c("display-row", {
            attrs: {
              "display-value": _vm.appointment.user.ssn,
              "has-copy-button": true,
              "label-text": "SSN:",
            },
          })
        : _vm._e(),
      _c("animal-insurance-display-row", { attrs: { animal: _vm.animal } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }