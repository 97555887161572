function getWeightStringFromAppointment(appointment) {
  const weight = appointment.weight ? appointment.weight : '?';
  const weightUnit = appointment.weight_unit
    ? appointment.weight_unit.unit
    : '?';

  return weight === '?' ? 'Weight not provided' : `${weight} ${weightUnit}`;
}

// eslint-disable-next-line
export { getWeightStringFromAppointment };
