var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-10" },
    [
      _c("title-row", [_vm._v(" Booking ")]),
      _c("display-row", {
        attrs: {
          "display-value": _vm.appointment.id,
          "label-text": "Booking ID:",
        },
      }),
      _c("display-row", {
        attrs: {
          "display-value": _vm.createPlatformString(_vm.appointment),
          "label-text": "Platform:",
        },
      }),
      _c("display-row", {
        attrs: {
          "display-value":
            _vm.appointment.booking && _vm.appointment.booking.veterinary
              ? _vm.appointment.booking.veterinary.display_name
              : "No vet assigned",
          "label-text": "Veterinarian:",
        },
      }),
      _c("meeting-display-rows", { attrs: { appointment: _vm.appointment } }),
      _c("display-row", {
        attrs: {
          "display-value": _vm.appointment.booking.created_at,
          "label-text": "Booking date:",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }