<template>
  <button
    class="flex w-full px-2 py-4 space-x-4 items-center rounded
        hover:bg-beigeLighter hover:bg-opacity-30
        focus:outline-none"
    :class="{'underline' : isOpen, 'cursor-default' : !count}"
    :disabled="!count"
    @click="handleActionButtonClick"
  >
    <span class="">
      <slot name="animalName" />
      <span class="text-sm ml-2 italic"><slot name="animalType" /></span>
    </span> 
    <span
      v-if="count"
      class="text-xs font-semibold rounded-full w-5 h-5 
      bg-primary border-primary-darker border-2 text-white flex items-center justify-center"
    >
      {{ count }}      
    </span>
  </button>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    handleActionButtonClick() {
      this.isOpen = !this.isOpen;
      this.$emit('click', this.isOpen);
    },
  },
};
</script>
