var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col space-y-6" }, [
    _c("div", { staticClass: "flex flex-col divide-y" }, [
      _vm.appointment.journal && _vm.appointment.journal.status
        ? _c(
            "button",
            {
              staticClass:
                "text-left py-2 bg-beigeLighter px-2 hover:bg-gray-100 focus:outline-none focus:text-black",
              on: { click: _vm.toggleJournalSummary },
            },
            [
              _c(
                "div",
                { staticClass: "flex justify-between items-center" },
                [
                  _c("span", [_vm._v("Journal")]),
                  _vm._v(" "),
                  _c("fv-icon", { attrs: { icon: "chevron-down" } }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.showJournalSummary && _vm.appointment.journal
        ? _c(
            "div",
            { staticClass: "px-2 py-4" },
            [
              _c("journal", {
                attrs: {
                  "display-post-comment-section": false,
                  journal: _vm.appointment.journal,
                  "user-name":
                    _vm.appointment.booking &&
                    _vm.appointment.booking.veterinary
                      ? _vm.appointment.booking.veterinary.display_name
                      : "No vet assigned yet",
                  "product-recommendations": _vm.productRecommendations,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "button",
        {
          staticClass:
            "text-left py-2 bg-beigeLighter px-2 hover:bg-gray-100 focus:outline-none focus:text-black",
          on: { click: _vm.toggleInformationSummary },
        },
        [
          _c(
            "div",
            { staticClass: "flex justify-between items-center" },
            [
              _c("span", [_vm._v("Booking information")]),
              _vm._v(" "),
              _c("fv-icon", { attrs: { icon: "chevron-down" } }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showInformationSummary,
              expression: "showInformationSummary",
            },
          ],
          staticClass: "px-2 py-4",
        },
        [
          _c("booking-information", {
            attrs: {
              appointment: _vm.appointment,
              animal: _vm.animal,
              images: _vm.appointment.images,
            },
          }),
        ],
        1
      ),
      _vm.appointment.referrals.length
        ? _c(
            "button",
            {
              staticClass:
                "text-left py-2 bg-beigeLighter px-2 hover:bg-gray-100 focus:outline-none focus:text-black",
              class: {
                "cursor-not-allowed": !_vm.appointment.referrals.length,
              },
              attrs: { disabled: !_vm.appointment.referrals.length },
              on: { click: _vm.toggleReferralsSummary },
            },
            [
              _c(
                "div",
                { staticClass: "flex justify-between items-center" },
                [
                  _c(
                    "span",
                    {
                      class: {
                        "line-through": !_vm.appointment.referrals.length,
                      },
                    },
                    [_vm._v(" Referrals ")]
                  ),
                  _c("fv-icon", { attrs: { icon: "chevron-down" } }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showReferralsSummary,
              expression: "showReferralsSummary",
            },
          ],
          staticClass: "px-2 py-4",
        },
        [
          _c("referral-summary", {
            attrs: { referrals: _vm.appointment.referrals },
          }),
        ],
        1
      ),
      _vm.appointment.uploads.length
        ? _c(
            "button",
            {
              staticClass:
                "text-left py-2 bg-beigeLighter px-2 hover:bg-gray-100 focus:outline-none focus:text-black",
              class: { "cursor-not-allowed": !_vm.appointment.uploads.length },
              attrs: { disabled: !_vm.appointment.uploads.length },
              on: { click: _vm.toggleFileUploadsSummary },
            },
            [
              _c(
                "div",
                { staticClass: "flex justify-between items-center" },
                [
                  _c(
                    "span",
                    {
                      class: {
                        "line-through": !_vm.appointment.uploads.length,
                      },
                    },
                    [_vm._v(" Uploaded files ")]
                  ),
                  _c("fv-icon", { attrs: { icon: "chevron-down" } }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showFileUploadsSummary,
              expression: "showFileUploadsSummary",
            },
          ],
          staticClass: "px-2 py-4",
        },
        [
          _c("appointment-upload", {
            attrs: {
              "appointment-id": _vm.appointment.id,
              uploads: _vm.appointment.uploads,
              extensions: [
                ".pdf",
                ".doc",
                ".docx",
                ".rtf",
                ".jpg",
                ".png",
                ".jpeg",
              ],
              "show-upload-button": false,
              "show-delete-button": false,
              "display-title": false,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }