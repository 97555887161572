var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("display-row", { attrs: { "label-text": "Meeting time:" } }, [
        _vm._v(
          " " +
            _vm._s(_vm.formattedDate) +
            " " +
            _vm._s(_vm.formattedTime) +
            " (" +
            _vm._s(
              _vm.appointment.booking.slot &&
                _vm.appointment.booking.slot.duration
                ? _vm.appointment.booking.slot.duration +
                    _vm.appointment.booking.slot.margin
                : "?"
            ) +
            " min) "
        ),
      ]),
      _c("display-row", { attrs: { "label-text": "Meeting:" } }, [
        _vm._v(
          " " +
            _vm._s(_vm.serviceName) +
            " (" +
            _vm._s(_vm.appointmentStatus) +
            ") "
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }