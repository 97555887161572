<template>
  <div class="flex flex-col space-y-6">
    <div class="flex flex-col space-y-2">
      <title-row>Question</title-row>
      <nl2br
        tag="p"
        class="prose"
        :text="question.original_question"
      />
    </div>

    <div
      v-if="question.answer"
      class="flex flex-col space-y-2"
    >
      <title-row>Answer</title-row>
      <div
        class="prose"
        v-html="question.answer"
      />
    </div>

    <div
      v-if="question.answer_private"
      class="flex flex-col space-y-2"
    >
      <title-row>Private answer</title-row>
      <div
        class="prose"
        v-html="question.answer_private"
      />
    </div>

    <div class="flex flex-col space-y-2">
      <title-row>Answered by</title-row>
      <p> {{ question.veterinary ? question.veterinary.display_name : 'Not reply posted.' }}</p>
    </div>
  </div>
</template>

<script>
import Nl2br from 'vue-nl2br';

export default {
  components: {
    Nl2br,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
};
</script>
